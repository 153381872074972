import React from 'react'

const WhyChoose = () => {


  return (
    <section className="choosedesire tron ychoose pt-100 mb-0">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
            <h3 className="heading-h2"><span className="heading-h3"><span className="bluecolor">What Makes Coinsclone </span> the Best </span>
            Tron Token Development Company?</h3>
            <p className="pharagraph">As a <a href='https://www.coinsclone.com/crypto-token-development/'>Crypto Token Development Company</a> Coinsclone specializes in creating custom tokens on the Tron blockchain, offering services such as smart contract development, token issuance, and blockchain integration to facilitate efficient and secure digital asset management.
            </p>
          </div>
        </div>
        <div className="d-lg-flex firstblck">
          <div className="col pt-md-3" >
            <div className="innerBlock nothover">
              <div className="icn-left">
                <div className="icn-img">
                  <img width="32px" height="32px" src="https://coinsclone.mo.cloudinary.net/images/tron/Full scale solution.svg" alt="Full scale solution" />
                </div>
              </div>
              <div className="content-rght">
                <h3>
                  Full scale solution
                </h3>
                <p className="pharagraph">
                  The Tron tokens that we develop for you will be compatible with all contemporary and popularly used crypto wallets.
                </p>
              </div>
            </div>
          </div>
          <div className="col pt-md-3" >
            <div className="innerBlock nothover">
              <div className="icn-left">
                <div className="icn-img">
                  <img width="32px" height="32px" src="https://coinsclone.mo.cloudinary.net/images/tron/Customization.svg" alt="International experience" />
                </div>
              </div>
              <div className="content-rght">
                <h3>
                International experience
                </h3>
                <p className="pharagraph">
                We boast over 5 years of experience in creating tokens on the Tron blockchain network. Our track record speaks for itself, delivering impeccable outcomes tailored to our clients' needs and business concepts.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="d-lg-flex firstblck">
          <div className="col pt-md-3" >
            <div className="innerBlock nothover">
              <div className="icn-left">
                <div className="icn-img">
                  <img width="32px" height="32px" src="https://coinsclone.mo.cloudinary.net/images/tron/Multiple protocols.svg" alt="Multiple protocols" />
                </div>
              </div>
              <div className="content-rght">
                <h3>
                  Multiple protocols
                </h3>
                <p className="pharagraph">
                  We use multiple Tron blockchain protocols to build robust Tron tokens for you.
                </p>
              </div>
            </div>
          </div>
          <div className="col pt-md-3" >
            <div className="innerBlock nothover">
              <div className="icn-left">
                <div className="icn-img">
                  <img width="32px" height="32px" src="https://coinsclone.mo.cloudinary.net/images/tron/Specialization in DEFI development.svg" alt="Specialization in DeFi development" />
                </div>
              </div>
              <div className="content-rght">
                <h3>
                  Specialization in DeFidevelopment
                </h3>
                <p className="pharagraph">
                  Our team of blockchain developers has deployed more than 100+ tokens over multiple blockchain networks with first-class features and advanced security protocols.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="d-lg-flex lastblck">
          <div className="col pt-md-3" >
            <div className="innerBlock nothover">
              <div className="icn-left">
                <div className="icn-img">
                  <img width="32px" height="32px" src="https://coinsclone.mo.cloudinary.net/images/tron/Tron wallet development.svg" alt="Tron wallet development" />
                </div>
              </div>
              <div className="content-rght">
                <h3>
                  Tron wallet development
                </h3>
                <p className="pharagraph">
                  Our premium Tron token development services also include creating and customizing Tron wallets through which you can use your tokens for multiple purposes. It would be compatible with both Android and IOS platforms.
                </p>
              </div>
            </div>
          </div>
          <div className="col pt-md-3" >
            <div className="innerBlock nothover">
              <div className="icn-left">
                <div className="icn-img">
                  <img width="32px" height="32px" src="https://coinsclone.mo.cloudinary.net/images/tron/customer-support.svg" alt="Best customer support" />
                </div>
              </div>
              <div className="content-rght">
                <h3>
                  Best customer support
                </h3>
                <p className="pharagraph">
                  We provide extraordinary 24/7 technical and customer support for all our products which are delivered to our reputed international clients.
                </p>
              </div>
            </div>
          </div>
        </div>

      </div>
    </section>
  )
}

export default WhyChoose